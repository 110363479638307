import React, { useState } from 'react'

import './App.css'

import { IAbout, IArtwork } from './common/interfaces'

import Intro from './components/intro/Intro'
import Footer from './components/layout/Footer'
import Artwork from './components/portfolio/Artwork'
import Portfolio from './components/portfolio/Portfolio'
import Triangles from './components/layout/Triangles'
import Spinner from './components/layout/Spinner'

window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
})

type Props = {
  artworks: IArtwork[]
  about: IAbout | null
}

function App({ artworks, about }: Props) {
  const [currentArtwork, setCurrentArtwork] = useState<IArtwork | null>(null)
  const [currentArtworkIndex, setCurrentArtworkIndex] = useState<number | null>(null)

  if (artworks.length === 0)
    return (
      <>
        {about && <Intro about={about} />}
        <Spinner />
        <Triangles />
      </>
    )

  function onArtworkClick(index: number) {
    setCurrentArtworkIndex(index)
    setCurrentArtwork(artworks[index])

    // deactivate scrolling on page when modal is open but save scroll position
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}`
  }

  function onArtworkClose() {
    setCurrentArtworkIndex(null)
    setCurrentArtwork(null)

    // restore scroll position of body
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }

  function nextArtwork() {
    let nextIndex = currentArtworkIndex === null ? 0 : currentArtworkIndex + 1
    if (nextIndex === artworks.length) nextIndex = 0
    setCurrentArtworkIndex(nextIndex)
    setCurrentArtwork(artworks[nextIndex])
  }

  function previousArtwork() {
    let previousIndex = currentArtworkIndex === null ? 0 : currentArtworkIndex - 1
    if (previousIndex === -1) previousIndex = artworks.length - 1
    setCurrentArtworkIndex(previousIndex)
    setCurrentArtwork(artworks[previousIndex])
  }

  return (
    <>
      {about ? <Intro about={about} /> : <Spinner />}
      <Portfolio artworks={artworks} onArtworkClick={onArtworkClick} />
      {<Artwork artwork={currentArtwork} onClose={onArtworkClose} onNext={nextArtwork} onPrevious={previousArtwork} />}
      <Footer />
      <Triangles />
    </>
  )
}

export default App
