import React from 'react'

import { IArtwork } from '../../common/interfaces'

type Props = {
  artwork: IArtwork
  onClick: () => void
}

function PortfolioItem({ artwork, onClick }: Props) {
  return (
    <div className="portfolio-item" onClick={onClick}>
      <img src={artwork.thumbnail || artwork.image} alt={artwork.title} />
    </div>
  )
}

export default PortfolioItem
