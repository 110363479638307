import firebase from 'firebase/app'
import 'firebase/firestore'

import { IAbout, IArtwork } from './common/interfaces'

const firebaseConfig = {
  apiKey: 'AIzaSyA_-w8g7z8oKe4nf-TBezwvaekHCVt211k',
  authDomain: 'bernd-heidt-portfolio.firebaseapp.com',
  databaseURL: 'https://bernd-heidt-portfolio.firebaseio.com',
  projectId: 'bernd-heidt-portfolio',
  storageBucket: 'bernd-heidt-portfolio.appspot.com',
  messagingSenderId: '870922803089',
  appId: '1:870922803089:web:68811bcb5c197a47112c30',
}

firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()

async function getAbout(): Promise<IAbout> {
  const doc = await firestore.collection('content').doc('about').get()
  const about = doc.data() as IAbout
  return about
}

async function getArtworks(): Promise<IArtwork[]> {
  const snapshot = await firestore.collection('artworks').orderBy('year', 'desc').get()
  const artworks: IArtwork[] = []
  snapshot.forEach((doc) => artworks.push({ id: doc.id, ...doc.data() } as IArtwork))
  return artworks
}

export default {
  getAbout,
  getArtworks,
}
