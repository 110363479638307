import React from 'react'

function Footer() {
  return (
    <footer style={{ marginTop: 100 }}>
      <p
        style={{
          fontStyle: 'italic',
          textAlign: 'center',
          opacity: 0.5,
          fontSize: 'x-small',
        }}
      >
        designed by{' '}
        <a href="https://nicograef.com" target="_blank" rel="noopener noreferrer">
          nico gräf
        </a>
      </p>
    </footer>
  )
}

export default Footer
