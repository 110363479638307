import React from 'react'

import './intro.css'
import { IAbout } from '../../common/interfaces'

type Props = {
  about: IAbout
}

function Intro({ about }: Props) {
  return (
    <header>
      <img src={about.image} alt={about.name} />
      <p>
        <b>{about.name}</b> &mdash; {about.bio}
      </p>
      <p id="contact" style={{ display: 'flex', justifyContent: 'center' }}>
        <span style={{ fontStyle: 'italic', display: 'inline', flex: '1', textAlign: 'center' }}>
          <a href={`mailto:${about.email}`}>{about.email}</a>
        </span>
        <span> // </span>
        <span style={{ fontStyle: 'italic', display: 'inline', flex: '1', textAlign: 'center' }}>{about.address}</span>
      </p>
    </header>
  )
}

export default Intro
