import React from 'react'

import PortfolioItem from './PortfolioItem'
import { IArtwork } from '../../common/interfaces'
import './Portfolio.css'

type Props = {
  artworks: IArtwork[]
  onArtworkClick: (index: number) => void
}

function Portfolio({ artworks, onArtworkClick }: Props) {
  return (
    <section id="portfolio">
      {artworks.map((artwork, index) => (
        <PortfolioItem artwork={artwork} key={artwork.id} onClick={() => onArtworkClick(index)} />
      ))}
    </section>
  )
}

export default Portfolio
