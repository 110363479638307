import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

// import { IAbout, IArtwork } from './common/interfaces'
// import artworks from './common/data/artworks.json'
// import about from './common/data/about.json'

import firebase from './firebase'

firebase.getAbout().then((about) => {
  ReactDOM.render(
    <React.StrictMode>
      <App about={about} artworks={[]} />
    </React.StrictMode>,
    document.getElementById('root')
  )

  firebase.getArtworks().then((artworks) => {
    ReactDOM.render(
      <React.StrictMode>
        <App about={about} artworks={artworks} />
      </React.StrictMode>,
      document.getElementById('root')
    )
  })
})

ReactDOM.render(
  <React.StrictMode>
    <App about={null} artworks={[]} />
  </React.StrictMode>,
  document.getElementById('root')
)
