import React from 'react'

import { IArtwork } from '../../common/interfaces'
import './Artwork.css'

type Props = {
  artwork: IArtwork | null
  onClose: () => void
  onNext: () => void
  onPrevious: () => void
}

function Artwork({ artwork, onClose, onNext, onPrevious }: Props) {
  if (!artwork) return <></>

  // scroll to top when user clicks next or previous button to show the artwork
  document.querySelector('#modal')?.scrollTo(0, 0)

  return (
    <div id="modal">
      <section>
        <div id="image-wrapper">
          <img src={artwork.image} alt={artwork.title} />
        </div>
        <article>
          <h1>{artwork.title}</h1>
          <p>
            <em id="year">
              {artwork.year}
            </em>
          </p>
          <p style={{ whiteSpace: 'pre-line' }}>{artwork.description}</p>
        </article>
      </section>
      <footer>
        <button onClick={onPrevious} style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>
          {' '}
          &lsaquo;{' '}
        </button>
        <button onClick={onClose}>schließen</button>
        <button onClick={onNext} style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>
          {' '}
          &rsaquo;{' '}
        </button>
      </footer>
    </div>
  )
}

export default Artwork
