import React from 'react'

import './triangles.css'

const triangles = [
  {
    name: 'blue',
    color: '#4c636f',
    style: {
      top: 160,
      left: 140,
      transform: 'scale(3.5) rotate(110deg)',
    },
  },
  {
    name: 'red',
    color: '#900',
    style: {
      top: 300,
      right: 200,
      transform: 'scale(2) rotate(-160deg)',
    },
  },
]

function Triangles() {
  return (
    <>
      {triangles.map((triangle) => (
        <svg
          key={triangle.color}
          id={`triangle-${triangle.name}`}
          height="100"
          width="100"
          style={{
            zIndex: -1,
            // opacity: 0.7,
            position: 'absolute',
            ...triangle.style,
          }}
        >
          <polygon points="0,0 0,100 100,100" fill={triangle.color}></polygon>
        </svg>
      ))}
    </>
  )
}

export default Triangles
